.footer-color, .nav-color {
	background-image: linear-gradient(#4d3d5f, #2d0d3e);
	color: white;
}

#logo {
	width: 30%;
}

@font-face {
	src: url("assets/Integral.otf");
	font-family: 'Integral';
}

h1, h2, h3, h4, h5, button, nav a {
	font-family: 'Integral';
}

.integral {
	font-family: 'Integral';
}

.breadcrumb-item::before {
	font-size: 20px;
}

.breadcrumb-item.active {
	color: lightgray;
}

#brand-pic {
	padding-left: 10px;
}

#database-table {
	overflow-x: auto;
}

.post-row {
	margin-left: auto;
	margin-right: auto;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

footer a {
	color: rgba(255,255,255,.75);
}

section h1 {
	margin-top: 2rem;
}

footer a:hover {
	color: #A39367;
}

#content-area img {
	width: 100%;
	padding: 1rem;
}

#login {
	margin-top: 0.5rem;
}

.back-button {
	margin-top: 1rem;
}

.nav-link {
	margin-right: .3rem;
}

#playlistContainer {
	flex-direction: column;
}

.section-space {
	margin-top: 2rem;
}

main {
	margin-bottom: 3rem;
}

#ranking-page {
	margin-bottom: 3rem;
}

.breadcrumb {
	background-color: transparent;
}

ol a {
	color: black;
	font-size: 20px;
}

.media {
	margin: 1rem;
	flex-direction: column-reverse;
}

.media-list > .media {
	padding: 1rem;
	border: 1px solid rgb(108, 117, 125);
	border-radius: 3px;
	margin-left: 0;
	margin-right: 0;
}

.media-list {
	padding-left: 0px;
}

.media-right {
	margin-left: auto;
	margin-right: auto;
	padding: 1rem;
}

#news-area {
	display: flex;
	flex-direction: column;
}

.post-image {
	margin: 2rem auto;
	width: 90%;
}

#events img {
	width: 100px;
}

.rankings-image {
	width: 90%;
	margin: 1rem auto;
}

.centered {
	color: white;
	text-align: center;
	width: 80%;
	font-weight: bold;
	z-index: 99;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.overflow-x-auto {
	overflow-x: auto;
}

body, html {
	overflow-x: hidden;
}

@media (min-width: 575px) {

	.rankings-image {
		width: 70%;
	}

	#brand-title {
		font-size: 13px;
	}

	#ranking-page {
		margin-bottom: 5rem;
	}

	.nav-link {
		padding: .5rem;
		margin-right: 0rem;
		font-size: 11px;
	}

	#logo {
		width: 20%;
	}

	#login {
		margin-top: 0rem;
	}

	.media {
		margin: 1rem;
		flex-direction: row;
	}

	.label-space {
		margin-right: 1rem;
	}

	.form-space {
		margin: 1rem 0;
	}

	.post-row {
		width: 50%;
	}

	.post-image {
		width: 50%;
	}

	#news-area {
		width: 60%;
		margin-left: auto;
		margin-right: auto;
	}

	#carousel {
		width: 60%;
		margin-left: auto;
		margin-right: auto;
	}

	#events img {
		width: 150px;
	}

}


/*
for different sized browsers
*/
@media (min-width: 920px) {

	#brand-title {
		font-size: 16px;
	}

	nav .nav-link {
		font-size: 13px;
		margin-right: .5rem;
	}


	#playlistContainer {
		flex-direction: row;
		flex-wrap: wrap;
	}

	#playerArea {
		width: 60%;
	}

	#news-area  {
		width: 100%;
		flex-direction: row;
	}

	.post {
		flex-basis: 33%;
	}

	.post .card {
		height: 100%;
	}

}

@media (min-width: 1100px) {

	nav .nav-link {
		font-size: 14px;
		margin-right: 1.5rem;
	}

}
